import React, { useContext, useEffect } from "react";
import { Navbar } from "react-bootstrap";

import MenuIcon from "@mui/icons-material/Menu";
import SmartesterAPIContext from "context/SmartesterAPIContext";

import { useWindowDimensions } from "utilities";
import { tablet, lg } from "breakpoints";

import { ProfilePill } from "components";

const Header = (props) => {
    const api = useContext(SmartesterAPIContext);

    const logout = async () => {
        await api.logout();
        props.history.replace("/login");
    };

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (
            (width > tablet && props.navClosed) ||
            (width <= tablet && !props.navClosed)
        ) {
            props.toggleNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, props.navClosed]);

    return (
        <Navbar
            variant="light"
            className={`header ${
                (
                    props.navClosedUser !== null
                        ? props.navClosedUser
                        : props.navClosed
                )
                    ? "no-nav"
                    : ""
            }`}
        >
            <div style={{ display: "inline-flex" }}>
                {
                    <MenuIcon
                        onClick={() => props.toggleNavUser(width)}
                        className="navigation-icon"
                        role="closeNav"
                    />
                }
            </div>
            <ProfilePill
                minimised={width < lg}
                user={props.user}
                logout={logout}
                history={props.history}
            />
        </Navbar>
    );
};

export { Header };
