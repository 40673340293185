import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useApi } from "context/SmartesterAPIContext";

import packageJson from "../../package.json";

import logo from "resources/fullLogo.svg";
import footer from "resources/footer.svg";

import manageIcon from "resources/manage-icon.svg";

import FolderIcon from "@mui/icons-material/Folder";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const Navigation = (props) => {
    const [insightsOpen, setInsightsOpen] = useState(false);
    const [userRole, setUserRole] = useState("user");

    const api = useApi();

    const currentWorkspace = api.getCurrentWorkspace();

    return (
        <Nav
            className={`sidebar ${
                (
                    props.navClosedUser !== null
                        ? props.navClosedUser
                        : props.navClosed
                )
                    ? "nav-closed"
                    : ""
            }`}
            activeKey={props.location.pathname}
        >
            <div className="logo-box">
                <Link className="logo" to="/">
                    <img src={logo} alt="Logo" />
                </Link>
            </div>
            <p
                className="color-neutral-dark"
                style={{
                    margin: "9px 32px",
                    fontSize: "11px",
                    fontFamily: "Montserrat-Regular",
                }}
            >
                MAIN
            </p>
            <div className="nav-item">
                <Link to="/jobs" className="nav-link">
                    <FolderIcon className="sidebar-icon" />
                    Jobs
                </Link>
            </div>

            {/* <Nav.Item>
                <Nav.Link
                    active={[
                        "/reports",
                        "/analysis",
                        "/configuration",
                    ].includes(props.location.pathname)}
                    onClick={() => setInsightsOpen(!insightsOpen)}
                >
                    <AiOutlineLineChart className="sidebar-icon" />
                    Insights
                    <div style={{ float: "right" }}>
                        {insightsOpen ? (
                            <MdKeyboardArrowUp />
                        ) : (
                            <MdKeyboardArrowDown />
                        )}
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`nav-child ${!insightsOpen && "nav-hidden"}`}>
                <Nav.Link href="/reports">Reports</Nav.Link>
            </Nav.Item>
            <Nav.Item className={`nav-child ${!insightsOpen && "nav-hidden"}`}>
                <Nav.Link href="/analysis">Analysis</Nav.Link>
            </Nav.Item>
            <Nav.Item className={`nav-child ${!insightsOpen && "nav-hidden"}`}>
                <Nav.Link href="/configuration">Configuration</Nav.Link>
            </Nav.Item> */}

            {currentWorkspace.workspaceUserRole === "manager" && (
                <>
                    <div className="nav-item">
                        <div
                            onClick={() => setInsightsOpen(!insightsOpen)}
                            className="nav-link"
                        >
                            <img
                                src={manageIcon}
                                className="sidebar-icon"
                                alt="open-sidebar"
                            />
                            Manage
                            <div style={{ float: "right" }}>
                                {insightsOpen ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`nav-item nav-child ${
                            !insightsOpen && "nav-hidden"
                        }`}
                    >
                        <Link to="/workspace-users" className="nav-link">
                            Workspace Users
                        </Link>
                        {currentWorkspace.workspaceUserOrganisationManageSmartestersEnabled && (
                            <Link to="/smartesters" className="nav-link">
                                SMARTesters
                            </Link>
                        )}
                    </div>
                </>
            )}

            <div className="nav-item">
                <div
                    onClick={() => window.open("https://help.smartester.com")}
                    className="nav-link"
                >
                    <HelpOutlineOutlinedIcon className="sidebar-icon" />
                    Help
                </div>
            </div>

            <Nav.Item className="nav-version-code">
                {`v${packageJson.version}`}
            </Nav.Item>
            <div className="nav-footer">
                <img src={footer} alt="Footer" />
            </div>
        </Nav>
    );
};

export { Navigation };
