import React, { lazy, useState, useEffect } from "react";

import moment from "moment";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NotesIcon from "@mui/icons-material/Notes";
import CircularProgress from "@mui/material/CircularProgress";

import { useApi } from "context/SmartesterAPIContext";
import { useWindowDimensions, downloadFile, formatChartData } from "utilities";

import ActivityCard from "./ActivityCard";
import DetailsCard from "./DetailsCard";
import DebugCard from "./DebugCard";
import PhotosCard from "./PhotosCard";
import LocationCard from "./LocationCard";
import ChartCard from "./ChartCard";
import NotesCard from "./NotesCard";

import { tablet, xl } from "breakpoints";

const Page = lazy(() => import("components/Page"));
const Skeleton = lazy(() => import("components/Skeleton"));
const Card = lazy(() => import("components/Card"));
const DataProvider = lazy(() => import("components/DataProvider"));
const CustomSnackbar = lazy(() => import("components/CustomSnackbar"));
const ConfirmModal = lazy(() => import("components/ConfirmModal"));

const Activity = ({ navbarOpen, user, ...props }) => {
    const [activity, setActivity] = useState(null);
    const [readings, setReadings] = useState(null);
    const [location, setLocation] = useState(null);
    const [metaDataItem, setMetaDataItem] = useState({});
    const [pressureTest, setPressureTest] = useState({});
    const [readingTestStart, setReadingTestStart] = useState(null);
    const [readingTestEnd, setReadingTestEnd] = useState(null);
    const [readingTestFailed, setReadingTestFailed] = useState(null);
    const [readingStabilisationStart, setReadingStabilisationStart] =
        useState(null);
    const [pressureTestMetaDataItem, setPressureTestMetaDataItem] = useState(
        {},
    );
    const [pressureTestParameter, setPressureTestParameter] = useState({
        stabilisationMinimumDuration: true,
    });
    const [locationUrl, setLocationUrl] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [incidentReadings, setIncidentReadings] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [chartExpanded, setChartExpanded] = useState(false);
    const [successPDF, setSuccessPDF] = useState(false);
    const [errorPDF, setErrorPDF] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [chartMode, setChartMode] = useState("");
    const [comparingActivity, setComparingActivity] = useState(null);
    const [comparingData, setComparingData] = useState(null);
    const { width } = useWindowDimensions();
    const api = useApi();

    const workspace = api.getCurrentWorkspace();

    async function generatePDF() {
        setProcessing(true);
        const pdf = await api.getActivityPDF(props.match.params.id);
        if (pdf.ok) {
            downloadFile(
                await pdf.blob(),
                `${activity.locationReference}-${moment(
                    activity.remoteCreatedAt,
                ).format("DDMMYYYY - HH-mm-ss")}-${
                    activity.activityTypeName
                }.pdf`,
                "application/pdf",
            );
            setSuccessPDF(true);
            setProcessing(false);
        } else {
            setErrorPDF(true);
            setProcessing(false);
        }
    }

    async function checkDataIntegrity() {
        try {
            await api.checkActivityDataIntegrity(props.match.params.id);
        } catch (err) {
            return true;
        }
        return false;
    }

    const handlePDFClick = async () => {
        const missingDetails = await checkDataIntegrity();
        if (missingDetails) {
            document.getElementById("pdf-confirm").click();
        } else {
            generatePDF();
        }
    };

    const getData = async () => {
        setActivity(null);
        setLocation(null);
        setActivityList([]);
        setPressureTest(null);
        setPressureTestMetaDataItem(null);
        setUploads([]);
        setIncidentReadings([]);
        setComparingData(null);
        setComparingActivity(null);

        const activityResult = await api
            .getActivity(props.match.params.id)
            .catch((err) => {
                console.error(err);
            });
        setActivity(activityResult);

        const pressureTestResult = await api
            .getPressureTest(activityResult.pressureTestId)
            .catch((err) => {
                console.error(err);
            });
        setPressureTest(pressureTestResult);

        const readingTestStart = await api
            .getReading(pressureTestResult.readingTestStartId)
            .catch((err) => {
                console.error(err);
            });
        setReadingTestStart(readingTestStart);

        const readingTestEnd = await api
            .getReading(pressureTestResult.readingTestEndId)
            .catch((err) => {
                console.error(err);
            });
        setReadingTestEnd(readingTestEnd);

        const readingTestFailed =
            pressureTestResult.readingTestFailedId &&
            (await api
                .getReading(pressureTestResult.readingTestFailedId)
                .catch((err) => {
                    console.error(err);
                }));
        setReadingTestFailed(readingTestFailed);

        const readingStabilisationStart =
            pressureTestResult.readingStabilisationStartId &&
            (await api
                .getReading(pressureTestResult.readingStabilisationStartId)
                .catch((err) => {
                    console.error(err);
                }));
        setReadingStabilisationStart(readingStabilisationStart);

        const pressureTestParameterResult = await api
            .getPressureTestParameter(activityResult.pressureTestParameterId)
            .catch((err) => {
                console.error(err);
            });
        if (pressureTestParameterResult) {
            setPressureTestParameter(pressureTestParameterResult);
        }

        const locationResult = await api
            .getLocation(activityResult.locationId)
            .catch((err) => {
                console.error(err);
            });
        setLocation(locationResult);

        if (locationResult) {
            const locationURL = await api
                .getLocationURL(activityResult.metaDataItemId)
                .catch((err) => {
                    console.error(err);
                });
            setLocationUrl(locationURL ? locationURL.url : null);
        }

        const uploads = await api.getUploads({
            page: { size: -1 },
            filter: {
                activityId: { eq: activityResult.id },
            },
            sort: {
                createdAt: "asc",
            },
        });
        setUploads(uploads.data);

        const metaDataItem = await api
            .getMetaDataItem(activityResult.metaDataItemId)
            .catch((err) => {
                console.error(err);
            });
        setMetaDataItem(metaDataItem ? metaDataItem : {});

        const pressureTestMetaDataItem = await api
            .getPressureTestMetaDataItem(
                pressureTestResult.pressureTestMetaDataItemId,
            )
            .catch((err) => {
                console.error(err);
            });
        setPressureTestMetaDataItem(
            pressureTestMetaDataItem ? pressureTestMetaDataItem : {},
        );

        const managerIncidentFilter =
            workspace?.workspaceUserRole === "manager"
                ? {}
                : {
                      incidentType: "unexpected_pressure_change",
                  };

        const incidents = await api.getIncidents({
            page: { size: -1 },
            filter: {
                activityId: { eq: activityResult.id },
                ...managerIncidentFilter,
            },
            sort: {
                remoteCreatedAt: "asc",
            },
        });

        if (incidents.data.length) {
            const incidentFilter = {
                filter: {
                    incidentId: {
                        eq: incidents.data.map((i) => i.id),
                    },
                },
            };

            const incidentNoteExport = await api.getNotesExport(incidentFilter);

            const incidentNoteById = incidentNoteExport.data.reduce(
                (a, v) => ({ ...a, [v.incidentId]: v }),
                {},
            );
            const incidentReadingRecords = [];

            await Promise.all(
                incidents.data.map(async (incident) => {
                    const record =
                        incident.readingId &&
                        (await api
                            .getReading(incident.readingId)
                            .catch((err) => {
                                console.error(err);
                            }));

                    record &&
                        incidentReadingRecords.push({
                            ...record,
                            note: incidentNoteById[incident.id],
                            type: incident.incidentType,
                        });
                }),
            );

            setIncidentReadings(incidentReadingRecords);
        }

        const readings = await api.getReadings({
            page: { size: -1 },
            filter: {
                activityId: { eq: activityResult.id },
                measurementType: { eq: "Differential Pressure" },
            },
            sort: {
                measuredAt: "asc",
            },
        });
        setReadings(
            readings["Differential Pressure"] &&
                readings["Differential Pressure"].length > 0
                ? readings["Differential Pressure"]
                : null,
        );

        /* Get prev and next activity */
        const activities = await api.getActivities({
            page: { size: -1 },
            filter: {
                jobId: { eq: activityResult.jobId },
                completedAt: { neq: "" },
                "pressureTests.result": { neq: "INCOMPLETE" },
            },
            sort: {
                startedAt: "desc",
            },
        });
        setActivityList(activities.data);
    };

    const getComparingData = async (comparingId) => {
        const activity = await api.getActivity(comparingId);

        const readings = await api.getReadings({
            page: { size: -1 },
            filter: {
                activityId: { eq: comparingId },
                measurementType: { eq: "Differential Pressure" },
            },
            sort: {
                measuredAt: "asc",
            },
        });

        const pressureTest = await api.getPressureTest(activity.pressureTestId);
        const readingTestStart = await api.getReading(
            pressureTest.readingTestStartId,
        );
        const readingTestEnd = await api.getReading(
            pressureTest.readingTestEndId,
        );
        const readingStabilisationStart =
            pressureTest.readingStabilisationStartId &&
            (await api.getReading(pressureTest.readingStabilisationStartId));

        return [
            readings["Differential Pressure"],
            readingTestStart,
            readingTestEnd,
            readingStabilisationStart,
        ];
    };

    const warnings = [];

    if (
        activity?.pressureTestSystemResult === "fail" &&
        activity?.pressureTestEngineeringResult === "pass"
    ) {
        warnings.push("Disputed Test");
    }

    if (metaDataItem?.userCoordinates) {
        warnings.push("Location updated by User");
    }

    useEffect(() => {
        if (comparingActivity) {
            getComparingData(comparingActivity)
                .then((d) => setComparingData(d))
                .catch(() => setComparingData(null));
        } else {
            setComparingData(null);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparingActivity]);

    useEffect(() => {
        chartMode !== "compare" && setComparingActivity(null);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartMode]);

    return (
        <DataProvider
            getData={getData}
            renderKey={props.location.pathname}
            renderData={() => (
                <>
                    {activity && pressureTest && (
                        <Page
                            title={`${activity.activityTypeName} Test Record`}
                            className="activity-page"
                            warnings={warnings}
                            breadcrumbs={[
                                { name: "Jobs", link: "/jobs" },
                                {
                                    name: activity.locationReference,
                                    link: `/jobs?page=1&search=${activity.locationReference}&expandedRow=${activity.jobId}`,
                                },
                                {
                                    name: `${
                                        activity.activityTypeName
                                    } (${moment(
                                        activity.remoteCreatedAt,
                                    ).format("DD/MM/YYYY - HH:mm:ss")})`,
                                },
                            ]}
                            actions={
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            processing ? (
                                                <CircularProgress
                                                    style={{
                                                        height: "26px",
                                                        width: "26px",
                                                        marginRight: "10px",
                                                    }}
                                                    color="inherit"
                                                />
                                            ) : (
                                                <InsertDriveFileIcon
                                                    style={{
                                                        height: "26px",
                                                        width: "26px",
                                                        marginRight: "8px",
                                                    }}
                                                />
                                            )
                                        }
                                        onClick={handlePDFClick}
                                        disabled={false}
                                        style={{
                                            borderRadius: "12px",
                                            padding: "10px 24px",
                                            marginRight: "23px",
                                            width: "max-content",
                                        }}
                                    >
                                        Export PDF
                                    </Button>
                                    <ConfirmModal
                                        title="Missing Data"
                                        message={[
                                            "This certificate contains records with missing data. The missing data will not be shown in the certificate.",
                                            "Do you want to continue?",
                                        ]}
                                        confirm={generatePDF}
                                    >
                                        <div
                                            style={{
                                                display: "none",
                                            }}
                                            id="pdf-confirm"
                                        ></div>
                                    </ConfirmModal>
                                </>
                            }
                        >
                            {width <= tablet && (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: `${
                                                width < 599
                                                    ? "space-around"
                                                    : ""
                                            }`,
                                        }}
                                    ></div>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            processing ? (
                                                <CircularProgress
                                                    style={{
                                                        height: "26px",
                                                        width: "26px",
                                                        marginRight: "10px",
                                                    }}
                                                    color="inherit"
                                                />
                                            ) : (
                                                <InsertDriveFileIcon
                                                    style={{
                                                        height: "26px",
                                                        width: "26px",
                                                        marginRight: "8px",
                                                    }}
                                                />
                                            )
                                        }
                                        disabled={false}
                                        className={width < 599 && "full-width"}
                                        style={{
                                            borderRadius: "12px",
                                            padding: "10px 24px",
                                        }}
                                        onClick={handlePDFClick}
                                    >
                                        Export PDF
                                    </Button>
                                    <ConfirmModal
                                        title="Missing Data"
                                        message={[
                                            "This certificate contains records with missing data. The missing data will not be shown in the certificate.",
                                            "Do you want to continue?",
                                        ]}
                                        confirm={generatePDF}
                                    >
                                        <div
                                            style={{
                                                display: "none",
                                            }}
                                            id="pdf-confirm"
                                        ></div>
                                    </ConfirmModal>
                                </div>
                            )}
                            <div
                                className={`activity-grid ${
                                    chartExpanded && width >= xl
                                        ? "expanded"
                                        : ""
                                }`}
                            >
                                <ChartCard
                                    readings={readings}
                                    readingTestStart={readingTestStart}
                                    readingTestEnd={readingTestEnd}
                                    readingTestFailed={readingTestFailed}
                                    readingStabilisationStart={
                                        readingStabilisationStart
                                    }
                                    pressureTest={pressureTest}
                                    toggleExpanded={() =>
                                        setChartExpanded(!chartExpanded)
                                    }
                                    expanded={chartExpanded}
                                    pressureTestParameter={
                                        pressureTestParameter
                                    }
                                    navbarOpen={navbarOpen}
                                    pressureTestMetaDataItem={
                                        pressureTestMetaDataItem
                                    }
                                    incidentReadings={incidentReadings}
                                    user={user}
                                    workspace={workspace}
                                    chartMode={chartMode}
                                    setChartMode={setChartMode}
                                    comparingData={comparingData}
                                />
                                {!(chartExpanded && width >= xl) && (
                                    <>
                                        <ActivityCard
                                            activity={activity}
                                            workspace={workspace}
                                            activityList={activityList}
                                            history={props.history}
                                            comparing={chartMode === "compare"}
                                            comparingActivity={
                                                comparingActivity
                                            }
                                            setComparingActivity={
                                                setComparingActivity
                                            }
                                        />
                                        <DetailsCard
                                            activity={activity}
                                            pressureTest={pressureTest}
                                            metaDataItem={metaDataItem}
                                            pressureTestParameter={
                                                pressureTestParameter
                                            }
                                            readingTestStart={readingTestStart}
                                            readingTestEnd={readingTestEnd}
                                            pressureTestMetaDataItem={
                                                pressureTestMetaDataItem
                                            }
                                            workspace={workspace}
                                        />
                                        <PhotosCard
                                            uploads={uploads}
                                            navbarOpen={navbarOpen}
                                        />
                                        <LocationCard
                                            coordinates={
                                                metaDataItem?.userCoordinates ??
                                                metaDataItem?.coordinates
                                            }
                                            locationUrl={locationUrl}
                                            warning={
                                                metaDataItem?.userCoordinates
                                            }
                                        />
                                        <NotesCard
                                            activity={activity}
                                            pressureTest={pressureTest}
                                            workspace={workspace}
                                            incidentReadings={incidentReadings}
                                        />
                                        {user.superuser && (
                                            <DebugCard
                                                activity={activity}
                                                pressureTest={pressureTest}
                                                location={location}
                                                metaDataItem={metaDataItem}
                                                pressureTestMetaDataItem={
                                                    pressureTestMetaDataItem
                                                }
                                                readingTestStart={
                                                    readingTestStart
                                                }
                                                readingTestEnd={readingTestEnd}
                                                readingTestFailed={
                                                    readingTestFailed
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <CustomSnackbar
                                variant="success"
                                showSnackbar={successPDF}
                                message="PDF successfully exported"
                                onClose={() => setSuccessPDF(false)}
                                top={56}
                                center
                            />
                            <CustomSnackbar
                                variant="error"
                                showSnackbar={errorPDF}
                                message="Something went wrong. Please try again"
                                onClose={() => setErrorPDF(false)}
                                top={56}
                                center
                            />
                        </Page>
                    )}
                </>
            )}
            loadingFallback={() => (
                <Page
                    title={` `}
                    breadcrumbs={[
                        { loading: true },
                        { loading: true },
                        { loading: true },
                    ]}
                    actions={
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={
                                <InsertDriveFileIcon
                                    style={{ height: "32px", width: "32px" }}
                                />
                            }
                            disabled={true}
                            style={{
                                borderRadius: "12px",
                                padding: "10px 24px",
                                marginRight: "23px",
                                marginTop: "21px",
                                width: "max-content",
                            }}
                        >
                            Export PDF
                        </Button>
                    }
                >
                    {width <= tablet && (
                        <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={
                                    <InsertDriveFileIcon
                                        style={{
                                            height: "32px",
                                            width: "32px",
                                        }}
                                    />
                                }
                                disabled={true}
                                className={width < 599 && "full-width"}
                                style={{
                                    borderRadius: "12px",
                                    padding: "10px 24px",
                                }}
                            >
                                Export PDF
                            </Button>
                        </div>
                    )}
                    <div className="activity-grid">
                        <div className="chart">
                            <Card>
                                <Skeleton
                                    style={{
                                        height: "570px",
                                        width: "-webkit-fill-available",
                                        margin: "10px 31px 38px 54px",
                                    }}
                                />
                            </Card>
                        </div>
                        <div className="highlights">
                            <Card>
                                <div style={{ height: "400px" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            margin: "0 0 10px 12px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Skeleton
                                            style={{
                                                height: "24px",
                                                width: "6vw",
                                                margin: "12px 12px 39px 12px",
                                            }}
                                        />
                                        <div style={{ display: "flex" }}>
                                            <Skeleton
                                                style={{
                                                    height: "24px",
                                                    width: "3vw",
                                                    margin: "12px 12px 39px 12px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "24px",
                                                    width: "3vw",
                                                    margin: "12px 12px 39px 12px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0 12px 40px 12px",
                                        }}
                                    >
                                        <Skeleton
                                            type="circle"
                                            style={{
                                                height: "32px",
                                                width: "32px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto -10px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto 16px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0 12px 40px 12px",
                                        }}
                                    >
                                        <Skeleton
                                            type="circle"
                                            style={{
                                                height: "32px",
                                                width: "32px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto -10px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto 16px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0 12px 40px 12px",
                                        }}
                                    >
                                        <Skeleton
                                            type="circle"
                                            style={{
                                                height: "32px",
                                                width: "32px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto -10px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "12px",
                                                width: "7vw",
                                                margin: "auto 0 auto 16px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="details">
                            <Card>
                                <Skeleton
                                    style={{
                                        height: "24px",
                                        width: "6vw",
                                        margin: "12px 0 37px 12px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "1px",
                                        width: "-webkit-fill-available",
                                        margin: "0 0 18px 16px",
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        height: "12px",
                                        width: "14vw",
                                        margin: "0 0 23px 16px",
                                    }}
                                />
                            </Card>
                        </div>
                        <div className="photos">
                            <Card>
                                <div style={{ height: "248px" }}>
                                    <Skeleton
                                        style={{
                                            height: "24px",
                                            width: "6vw",
                                            margin: "12px 0 29px 13px",
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0 13px",
                                        }}
                                    >
                                        <Skeleton
                                            style={{
                                                height: "144px",
                                                width: "144px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "144px",
                                                width: "144px",
                                            }}
                                        />
                                        <Skeleton
                                            style={{
                                                height: "144px",
                                                width: "144px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="location">
                            <Card>
                                <div style={{ height: "537px" }}>
                                    <Skeleton
                                        style={{
                                            height: "24px",
                                            width: "6vw",
                                            margin: "12px 0 20px 12px",
                                        }}
                                    />
                                    <Skeleton
                                        style={{
                                            height: "438px",
                                            width: "auto",
                                            margin: "12px 12px 20px 12px",
                                        }}
                                    />
                                    <Skeleton
                                        style={{
                                            height: "12px",
                                            width: "50%",
                                            margin: "12px 0 23px 12px",
                                        }}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="notes">
                            <Card>
                                <div style={{ height: "229px" }}>
                                    <Skeleton
                                        style={{
                                            height: "24px",
                                            width: "6vw",
                                            margin: "12px 0 21px 12px",
                                        }}
                                    />
                                    <div style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                margin: "0 12px",
                                                width: "-webkit-fill-available",
                                            }}
                                        >
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "70%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                margin: "0 20px 0 8px",
                                                width: "-webkit-fill-available",
                                            }}
                                        >
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                            <Skeleton
                                                style={{
                                                    height: "12px",
                                                    width: "70%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Page>
            )}
            history={props.history}
            location={props.location}
        />
    );
};

export { Activity };
