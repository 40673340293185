import React, { lazy, useEffect, useState, useCallback } from "react";

import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";

const Card = lazy(() => import("components/Card"));
const Skeleton = lazy(() => import("components/Skeleton"));

import { tablet, sm, xl } from "breakpoints";

const PhotosBody = ({ uploads, navbarOpen }) => {
    const [offset, setOffset] = useState(0);
    const [minimumPhotoCount, setMinimumPhotoCount] = useState(0);
    const [validUploadCards, setValidUploadCards] = useState(
        Array(uploads.length).fill(
            <Skeleton style={{ height: "144px", width: "144px" }} />,
        ),
    );
    const [cardWidth, setCardWidth] = useState(null);

    const updateWidth = useCallback(
        (totalWidth) => {
            let bodyWidth =
                totalWidth -
                6 -
                (navbarOpen ? 256 : 0) -
                (totalWidth >= sm ? 64 : 30);
            if (totalWidth >= xl) {
                setCardWidth((bodyWidth - 32 * 2) / 3 - 16);
            } else if (totalWidth >= tablet) {
                setCardWidth((bodyWidth - 32) / 2 - 16);
            } else {
                setCardWidth(bodyWidth - 16);
            }
        },
        [navbarOpen],
    );

    useEffect(() => {
        updateWidth(window.innerWidth);
    }, [updateWidth]);

    useEffect(() => {
        window.addEventListener("resize", (e) =>
            updateWidth(e.target.innerWidth),
        );

        return () =>
            window.removeEventListener("resize", (e) =>
                updateWidth(e.target.innerWidth),
            );
    }, [updateWidth]);

    const getPhotos = async (uploadItems) => {
        let newCards = await Promise.all(
            uploadItems.map(async (upload, index) => {
                const card = await fetch(upload.fileUrl)
                    .then((response) => {
                        if (response.status >= 400) {
                            return null;
                        }
                        return (
                            <button
                                key={`upload-${upload.id}`}
                                className="photos-placeholder photo-preview"
                                onClick={() => {
                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(`
                                <html>
                                    <head>
                                        <title>Photo ${
                                            index + 1
                                        } | SMARTester Portal</title>
                                    </head>
                                    <body style="background-color: #0E0E0E; margin: 0; text-align: center;">
                                      <img style="height: 100%;" src=${
                                          upload.fileUrl
                                      } alt="Photo-${index}">
                                    </body>
                                </html>
                            `);
                                }}
                                style={{
                                    backgroundImage: `url("${upload.fileUrl}")`,
                                }}
                            />
                        );
                    })
                    .catch(() => {
                        return null;
                    });

                return card;
            }),
        );

        newCards = newCards.filter((y) => y);
        setValidUploadCards(newCards);
    };

    useEffect(() => {
        getPhotos(uploads);
    }, [uploads]);

    useEffect(() => {
        const newCount = cardWidth > 580 ? 3 : cardWidth > 416 ? 2 : 1;
        if (newCount !== minimumPhotoCount) {
            setMinimumPhotoCount(newCount);
            setOffset(0);
        }
    }, [minimumPhotoCount, cardWidth, setMinimumPhotoCount]);

    const uploadCards = [...validUploadCards];
    while (uploadCards.length < minimumPhotoCount) {
        uploadCards.push(
            <div key={uploadCards.length} className="photos-placeholder">
                <ImageIcon
                    className="color-dark"
                    style={{ fontSize: "64px" }}
                />
            </div>,
        );
    }

    const needsScroll = uploadCards.length > minimumPhotoCount;

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ justifyContent: "center", display: "inline-flex" }}>
                {needsScroll && (
                    <IconButton
                        onClick={() => setOffset(offset - 1)}
                        disabled={offset === 0}
                        color="primary"
                        style={{
                            height: "44px",
                            width: "44px",
                            margin: "auto",
                        }}
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                )}
                <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                    {uploadCards.slice(offset, minimumPhotoCount + offset)}
                </div>
                {needsScroll && (
                    <IconButton
                        onClick={() => setOffset(offset + 1)}
                        disabled={
                            minimumPhotoCount + offset >= uploadCards.length
                        }
                        color="primary"
                        style={{
                            height: "44px",
                            width: "44px",
                            margin: "auto",
                        }}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                )}
            </div>
            <div
                style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    marginTop: "16px",
                }}
            >
                {needsScroll &&
                    uploadCards.map((card, index) => {
                        return (
                            <span
                                className={`photo-dot ${
                                    index >= offset &&
                                    index < minimumPhotoCount + offset
                                        ? "active"
                                        : ""
                                }`}
                                key={`span-dot-${index}`}
                            ></span>
                        );
                    })}
            </div>
        </div>
    );
};

const NoPhotosCard = () => {
    return (
        <div className="empty-photos-box">
            <ImageIcon
                className="color-dark empty-notes"
                style={{ fontSize: "64px", fill: "#8D8D8D" }}
            />
            <p className="body-1 color-neutral-dark no-photos-label">
                This Test Doesn't Have Any Photos
            </p>
        </div>
    );
};

const PhotosCard = ({ uploads, navbarOpen }) => {
    return (
        <div className="photos">
            <Card style={{ height: "320px" }}>
                <div className="photos-card">
                    <h4
                        style={{
                            margin: "12px 0 28px 12px",
                        }}
                    >
                        Photos
                    </h4>
                    {uploads.length > 0 ? (
                        <PhotosBody uploads={uploads} navbarOpen={navbarOpen} />
                    ) : (
                        <NoPhotosCard />
                    )}
                </div>
            </Card>
        </div>
    );
};

export default PhotosCard;
