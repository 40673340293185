import React, { lazy, useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import activityNoteIcon from "resources/activity-note.svg";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { renderIcon, roundPressure, useWindowDimensions } from "utilities";
import { xl } from "breakpoints";

const Card = lazy(() => import("components/Card"));

const ActivityCard = ({
    activity,
    activityList,
    history,
    comparing,
    comparingActivity,
    setComparingActivity,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const foundIndex = activityList.findIndex(
            (item) => item.id === activity.id,
        );

        if (foundIndex !== -1) {
            setCurrentIndex(foundIndex);
        }
    }, [activity, activityList]);

    const { width } = useWindowDimensions();

    return (
        <div className="highlights">
            <Card style={{ padding: "0" }}>
                <div className="highlights-card">
                    <div className="test-navigator__wrapper">
                        <div className="test-navigator">
                            <h4 style={{ margin: "0" }}>Test Navigator</h4>
                            <div>
                                <Button
                                    onClick={() => {
                                        history.push(
                                            `/activities/${
                                                activityList[currentIndex + 1]
                                                    .id
                                            }`,
                                        );
                                    }}
                                    disabled={
                                        currentIndex === activityList.length - 1
                                    }
                                    id="test-navigator__button"
                                    className="test-navigator__button"
                                    startIcon={
                                        <KeyboardArrowDownIcon
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    }
                                    variant="secondary"
                                />
                                <Button
                                    onClick={() => {
                                        history.push(
                                            `/activities/${
                                                activityList[currentIndex - 1]
                                                    .id
                                            }`,
                                        );
                                    }}
                                    disabled={currentIndex === 0}
                                    className="test-navigator__button"
                                    startIcon={
                                        <KeyboardArrowUpIcon
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    }
                                    variant="secondary"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="highlights-item__wrapper">
                        {activityList.map((data, index) => {
                            return (
                                <a
                                    className={`highlights__link ${
                                        comparing ? "highlights-comparing" : ""
                                    }`}
                                    key={index}
                                    href={
                                        activity.id === data.id || comparing
                                            ? "#"
                                            : `/activities/${data.id}`
                                    }
                                    style={{ textDecoration: "none" }}
                                >
                                    <div
                                        className={`highlights-item ${
                                            activity.id === data.id
                                                ? "highlights-item__current"
                                                : ""
                                        } ${
                                            comparingActivity === data.id
                                                ? "highlights-item__secondary"
                                                : ""
                                        } ${
                                            activity.pressureTestParameterPressureTestParameterCategoryName !==
                                            data.pressureTestParameterPressureTestParameterCategoryName
                                                ? "highlights-item__disabled"
                                                : ""
                                        }`}
                                    >
                                        {comparing && (
                                            <Checkbox
                                                checked={
                                                    activity.id === data.id ||
                                                    comparingActivity ===
                                                        data.id
                                                }
                                                disabled={
                                                    activity.id === data.id ||
                                                    activity.pressureTestParameterPressureTestParameterCategoryName !==
                                                        data.pressureTestParameterPressureTestParameterCategoryName
                                                }
                                                onChange={(event) =>
                                                    setComparingActivity(
                                                        event.target.checked
                                                            ? data.id
                                                            : null,
                                                    )
                                                }
                                                color="secondary"
                                            />
                                        )}
                                        {renderIcon(
                                            data.activityTypeIcon,
                                            data.activityTypeIconColor,
                                            "highlights-img",
                                        )}
                                        <div>
                                            <p className="subtitle-2 highlights-text">
                                                {data.activityTypeName}
                                            </p>
                                            <p className="overline color-dark highlights-text">
                                                {data.pressureTestChangeInPressure !==
                                                null
                                                    ? `${roundPressure(
                                                          data.pressureTestChangeInPressure,
                                                      )} mbar`
                                                    : "No Data"}
                                            </p>
                                        </div>
                                        {data.metaDataItemUserCoordinates ||
                                        (data.pressureTestEngineeringResult ===
                                            "pass" &&
                                            data.pressureTestSystemResult ===
                                                "fail") ? (
                                            <WarningIcon
                                                stroke={"#FF9C50"}
                                                stoke-width={1}
                                                style={{
                                                    marginLeft: 20,
                                                    fontSize: 32,
                                                    marginTop: -2,
                                                    color: "#FFDA5C",
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}

                                        <p
                                            className={`highlights-result ${
                                                data.pressureTestResult
                                                    ? data.pressureTestResult.toLowerCase()
                                                    : ""
                                            }`}
                                        >
                                            {(width > 1700 ||
                                                (width < xl && width > 1240) ||
                                                (width < 1000 &&
                                                    width > 500)) &&
                                                data.pressureTestResult}
                                        </p>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ActivityCard;
