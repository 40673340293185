import React, { useState } from "react";

import { useApi } from "context/SmartesterAPIContext";
import SamlContext from "context/SamlContext";

import { Modal, Button } from "@mui/material";

import { SAML_SUPPORT_PHONE, SAML_ENABLED } from "config";

const SamlProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState();

    const api = useApi();

    const showModal = (title, action) =>
        setActiveModal({ title, action: action ?? title.toLowerCase() });
    const clearModal = () => setActiveModal(null);

    let { supportTelephoneNumber } = api.getCurrentWorkspace() ?? {};

    if (SAML_ENABLED && SAML_SUPPORT_PHONE) {
        supportTelephoneNumber = SAML_SUPPORT_PHONE;
    }

    return (
        <SamlContext.Provider value={{ showModal }}>
            {children}
            {activeModal && (
                <Modal
                    aria-labelledby="saml-warning-modal"
                    disableBackdropClick
                    disableEscapeKeyDown
                    open
                >
                    <div className="saml-modal">
                        <div className="modal-title">
                            <h3>{activeModal.title}</h3>
                        </div>
                        <div className="saml-modal-content">
                            <p>
                                To {activeModal.action} please contact the
                                Technical Help Desk by Email or call{" "}
                                {supportTelephoneNumber}
                            </p>
                            <div className="modal-buttons saml-support-cancel-button">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className="saml-cancel"
                                    onClick={clearModal}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </SamlContext.Provider>
    );
};

export default SamlProvider;
