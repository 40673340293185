import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import snackbarInfo from "resources/snackbarInfo.svg";
import snackbarWarning from "resources/snackbarWarning.svg";
import snackbarSuccess from "resources/snackbarSuccess.svg";

const icons = {
    info: snackbarInfo,
    warning: snackbarWarning,
    error: snackbarWarning,
    success: snackbarSuccess,
};

const CustomSnackbar = (props) => {
    const [delay, setDelay] = useState(null);

    const close = () => {
        props?.onClose();
        clearTimeout(delay);
        setDelay(null);
    };

    useEffect(() => {
        if (props.showSnackbar) {
            const ms = props.delay ? props.delay : 5000;
            setDelay(setTimeout(close, ms));
        }
        return () => {
            if (delay) {
                clearTimeout(delay);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showSnackbar, props.delay]);

    return (
        <div
            style={{
                display: `${props.showSnackbar ? "block" : "none"}`,
                position: "fixed",
                top: props.top || 0,
                left: 0,
                width: "100%",
            }}
        >
            <div
                className={`custom-snackbar ${
                    props.multiline ? "two-line" : ""
                } ${props.center ? "center-snackbar" : ""}`}
            >
                <div style={{ display: "flex" }}>
                    {props.variant && (
                        <div className={`snackbar-icon ${props.variant}`}>
                            <img
                                src={icons[props.variant]}
                                style={{ margin: "auto" }}
                                alt="icon"
                            />
                        </div>
                    )}
                    <p
                        className="body-1"
                        style={{
                            color: "#FFF",
                            margin: "auto 40px auto 20px",
                            padding: "8px 0px",
                        }}
                    >
                        {props.message}
                    </p>
                </div>
                <IconButton onClick={close}>
                    <CloseIcon style={{ color: "#8d8d8d" }} />
                </IconButton>
            </div>
        </div>
    );
};

export default CustomSnackbar;
