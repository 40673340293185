export const xs = 600;
export const sm = 768;
export const tablet = 904;
export const md = 1080;
export const lg = 1240;
export const xl = 1440;

const breakpoints = { xs: xs, sm: sm, tablet: tablet, md: md, lg: lg, xl: xl };

export default breakpoints;
