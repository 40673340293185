import React, { lazy } from "react";

import { Row, Col } from "react-bootstrap";

const Card = lazy(() => import("components/Card"));

const DebugCard = ({
    activity,
    location,
    pressureTest,
    metaDataItem,
    pressureTestMetaDataItem,
    readingTestStart,
    readingTestEnd,
    readingTestFailed,
}) => {
    return (
        <div className="debug">
            <Card style={{ padding: "8px 0 8px 8px" }}>
                <div className="debug-card">
                    <h4
                        style={{
                            margin: "12px 0 22px 12px",
                        }}
                    >
                        Debug
                    </h4>
                    <Row>
                        <Col md={12} xl={6}>
                            <h5
                                style={{
                                    margin: "0 0 0 12px",
                                }}
                            >
                                Activity
                            </h5>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">id</p>
                                <p className="body-1 color-black">
                                    {activity.id}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    created_at
                                </p>
                                <p className="body-1 color-black">
                                    {activity.createdAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    updated_at
                                </p>
                                <p className="body-1 color-black">
                                    {activity.updatedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    remote_updated_at
                                </p>
                                <p className="body-1 color-black">
                                    {activity.remoteUpdatedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    started_at
                                </p>
                                <p className="body-1 color-black">
                                    {activity.startedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    completed_at
                                </p>
                                <p className="body-1 color-black">
                                    {activity.completedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    ActivityType#id
                                </p>
                                <p className="body-1 color-black">
                                    {activity.activityTypeId}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    ActivityType#name
                                </p>
                                <p className="body-1 color-black">
                                    {activity.activityTypeName}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    ActivityType#TestParameter#stabilisation_minimum_duration
                                </p>
                                <p className="body-1 color-black">
                                    {
                                        activity.activityTypeTestParameterStabilisationMinimumDuration
                                    }
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    ActivityType#TestParameter#test_allowable_pressure_increase
                                </p>
                                <p className="body-1 color-black">
                                    {
                                        activity.activityTypeTestParameterTestAllowablePressureIncrease
                                    }
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    ActivityType#TestParameter#test_allowable_pressure_decrease
                                </p>
                                <p className="body-1 color-black">
                                    {
                                        activity.activityTypeTestParameterTestAllowablePressureDecrease
                                    }
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">Note#id</p>
                                <p className="body-1 color-black">
                                    {activity.noteId}
                                </p>
                            </div>
                            <div className="details-mobile-row final">
                                <p className="overline color-dark">Note#note</p>
                                <p className="body-1 color-black">
                                    {activity.noteBody}
                                </p>
                            </div>
                            {location && (
                                <>
                                    <h5
                                        style={{
                                            margin: "0 0 0 12px",
                                        }}
                                    >
                                        Location
                                    </h5>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            id
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.id}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            address_id
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.addressId}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            coordinates
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.coordinates}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            created_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.createdAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            updated_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.updatedAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row final">
                                        <p className="overline color-dark">
                                            reference
                                        </p>
                                        <p className="body-1 color-black">
                                            {location.reference}
                                        </p>
                                    </div>
                                </>
                            )}
                            {metaDataItem && (
                                <>
                                    <h5
                                        style={{
                                            margin: "0 0 0 12px",
                                        }}
                                    >
                                        Meta Data Item
                                    </h5>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            id
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.id}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            created_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.createdAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            updated_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.updatedAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            absolute_gps_time
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.absoluteGpsTime}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            absolute_system_time
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.absoluteSystemTime}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            coordinates
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.coordinates}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            user_coordinates
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.userCoordinates}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            gps_absolute_time_delta
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.gpsAbsoluteTimeDelta}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            mobile_device_id
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.mobileDeviceId}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            mobile_device_model
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.mobileDeviceModel}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            os_version
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.osVersion}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row final">
                                        <p className="overline color-dark">
                                            software_version
                                        </p>
                                        <p className="body-1 color-black">
                                            {metaDataItem.softwareVersion}
                                        </p>
                                    </div>
                                </>
                            )}
                        </Col>
                        <Col md={12} xl={6}>
                            <h5
                                style={{
                                    margin: "0 0 0 12px",
                                }}
                            >
                                Pressure Test
                            </h5>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">id</p>
                                <p className="body-1 color-black">
                                    {pressureTest.id}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    abort_reason
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.abortReason}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    change_in_pressure
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.changeInPressure}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    created_at
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.createdAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    dispute_reason
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.disputeReason}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">disputed</p>
                                <p className="body-1 color-black">
                                    {pressureTest.disputed}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    engineering_result
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.engineeringResult}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_end_id
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.readingTestEndId}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_end_measured_at
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestEnd?.measuredAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_end_measurement
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestEnd?.measurement}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_start_id
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.readingTestStartId}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_start_measured_at
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestStart?.measuredAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_start_measurement
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestStart?.measurement}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_failed_id
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.readingTestFailedId}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_failed_measured_at
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestFailed?.measuredAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    reading_test_failed_measurement
                                </p>
                                <p className="body-1 color-black">
                                    {readingTestFailed?.measurement}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    stabilisation_started_at
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.stabilisationStartedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    system_result
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.systemResult}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    test_started_at
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.testStartedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row">
                                <p className="overline color-dark">
                                    test_ended_at
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.testEndedAt}
                                </p>
                            </div>
                            <div className="details-mobile-row final">
                                <p className="overline color-dark">
                                    updated_at
                                </p>
                                <p className="body-1 color-black">
                                    {pressureTest.updatedAt}
                                </p>
                            </div>
                            {pressureTestMetaDataItem && (
                                <>
                                    <h5
                                        style={{
                                            margin: "0 0 0 12px",
                                        }}
                                    >
                                        Pressure Test Meta Data Item
                                    </h5>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            id
                                        </p>
                                        <p className="body-1 color-black">
                                            {pressureTestMetaDataItem.id}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            created_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {pressureTestMetaDataItem.createdAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            updated_at
                                        </p>
                                        <p className="body-1 color-black">
                                            {pressureTestMetaDataItem.updatedAt}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            instrument_expires_on
                                        </p>
                                        <p className="body-1 color-black">
                                            {
                                                pressureTestMetaDataItem.instrumentExpiresOn
                                            }
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            instrument_firmware_version
                                        </p>
                                        <p className="body-1 color-black">
                                            {
                                                pressureTestMetaDataItem.instrumentFirmwareVersion
                                            }
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            instrument_id
                                        </p>
                                        <p className="body-1 color-black">
                                            {pressureTest?.instrumentId}
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            instrument_serial_number
                                        </p>
                                        <p className="body-1 color-black">
                                            {
                                                pressureTestMetaDataItem.pressureTestInstrumentSerialNumber
                                            }
                                        </p>
                                    </div>
                                    <div className="details-mobile-row">
                                        <p className="overline color-dark">
                                            instrument_zero_point
                                        </p>
                                        <p className="body-1 color-black">
                                            {
                                                pressureTestMetaDataItem.instrumentZeroPoint
                                            }
                                        </p>
                                    </div>
                                    <div className="details-mobile-row final">
                                        <p className="overline color-dark">
                                            battery_level_on_test_start
                                        </p>
                                        <p className="body-1 color-black">
                                            {
                                                pressureTestMetaDataItem.batteryLevelOnTestStart
                                            }
                                        </p>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    );
};

export default DebugCard;
