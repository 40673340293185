import { toSnakeCase, snakeToCamel, camelToSnake } from "utilities";
import { DEFAULT_HOST } from "config";

import QueryString from "query-string";
const EventEmitter = require("events");

const RequestType = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

class SmartesterAPI extends EventEmitter {
    constructor(props) {
        super(props);
        this.workspace = {};
    }

    getCurrentWorkspace() {
        return this.workspace;
    }

    setCurrentWorkspace(w, switching, workspaces = []) {
        this.workspace = w;
        if (window.location.host !== (w.hostname ?? DEFAULT_HOST)) {
            if (switching) {
                window.location.replace(
                    `${w.interfaceScheme ?? "https://"}${
                        w.hostname ?? DEFAULT_HOST
                    }${w.interfacePort ? ":" + w.interfacePort : ""}${
                        w.interfacePath ? "/" + w.interfacePath : ""
                    }${window.location.pathname}?success=${w.name}`,
                );
            } else {
                const newWorkspace = workspaces.find(
                    (w) => w.hostname === window.location.host,
                );
                if (newWorkspace) {
                    this.updateProfile({
                        user: {
                            lastUsedWorkspaceUserId:
                                newWorkspace.workspaceUserId,
                        },
                    })
                        .then(() => {
                            this.workspace = newWorkspace;
                        })
                        .catch(() => {
                            window.location.replace(
                                `${w.interfaceScheme ?? "https://"}${
                                    w.hostname ?? DEFAULT_HOST
                                }${
                                    w.interfacePort ? ":" + w.interfacePort : ""
                                }${
                                    w.interfacePath ? "/" + w.interfacePath : ""
                                }${window.location.pathname}${
                                    window.location.search
                                }`,
                            );
                        });
                } else {
                    window.location.replace(
                        `${w.interfaceScheme ?? "https://"}${
                            w.hostname ?? DEFAULT_HOST
                        }${w.interfacePort ? ":" + w.interfacePort : ""}${
                            w.interfacePath ? "/" + w.interfacePath : ""
                        }${window.location.pathname}${window.location.search}`,
                    );
                }
            }
        }
    }

    getParameters(params) {
        const items = Object.entries(params || {});

        const built = {};
        // eslint-disable-next-line
        for (const [key, val] of items) {
            if (!key || val === undefined) {
                continue;
            }

            const buildParam = (base, value) => {
                if (value === undefined) {
                    return;
                }

                if (typeof value === "object" && value !== null) {
                    if (Array.isArray(value)) {
                        //eslint-disable-next-line no-unused-vars
                        for (const [k, v] of Object.entries(value)) {
                            buildParam(base, v);
                        }
                    } else if ("gt" in value && value["gt"] === true) {
                        const newBase = `${base}[gt]`;
                        buildParam(newBase, value["val"]);
                    } else if ("gt" in value && value["gt"] === false) {
                        const newBase = `${base}[lt]`;
                        buildParam(newBase, value["val"]);
                    } else {
                        // eslint-disable-next-line
                        for (const [k, v] of Object.entries(value)) {
                            const newBase = `${base}[${toSnakeCase(k)}]`;
                            buildParam(newBase, v);
                        }
                    }
                } else {
                    built[base] = value;
                }
            };

            buildParam(toSnakeCase(key), val);
        }

        return QueryString.stringify(built);
    }

    makeRequest(
        endpoint,
        requestType = RequestType.GET,
        data = false,
        mimeType = "application/json",
        workspace = this.workspace.id,
    ) {
        const options = {
            headers: {
                Accept: mimeType,
                "X-Workspace-Id": workspace || null,
                "Content-Type": mimeType,
                "X-Frame-Options": "DENY",
            },
            method: requestType,
            credentials: "include",
        };

        if (
            data &&
            (mimeType === "application/json" ||
                mimeType === "text/csv" ||
                mimeType ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        ) {
            options.body = JSON.stringify(camelToSnake(data));
        }

        const apiBase = `${window.location.protocol}//${
            window.location.hostname
        }${window.location.hostname === "127.0.0.1" ? ":3000" : ""}/api/v1`;

        return fetch(apiBase + endpoint, options)
            .then((response) => {
                if (response.status === 502) {
                    //eslint-disable-next-line no-throw-literal
                    throw { status: 502 };
                }
                if (response.status === 429) {
                    //eslint-disable-next-line no-throw-literal
                    throw { status: 429 };
                }

                if (response.status === 401) {
                    this.emit("forceloggedout");
                }

                if (
                    response.status === 204 ||
                    (endpoint === "/auth/logout" && response.status === 200)
                ) {
                    return null;
                }
                if (mimeType !== "application/json") {
                    return response;
                }
                return response
                    .json()
                    .then((body) => snakeToCamel(body))
                    .then((body) => {
                        if (response.status >= 200 && response.status < 300) {
                            return body;
                        } else {
                            throw body;
                        }
                    });
            })
            .catch((error) => {
                throw error;
            });
    }

    login(email, password, rememberMe) {
        return this.makeRequest("/auth/login", RequestType.POST, {
            username: email,
            password: password,
            rememberMe: rememberMe,
        }).then((response) => {
            return this.getLoggedInUser();
        });
    }

    logout() {
        return this.makeRequest("/auth/logout", RequestType.POST, {}).then(
            (response) => {
                this.emit("loggedout");
            },
        );
    }

    getLoggedInUser() {
        return this.makeRequest("/me").then((loggedInUser) => {
            if (loggedInUser["verified?"]) {
                this.makeRequest("/me/workspaces")
                    .then(async (workspaces) => {
                        const [currentWorkspace] = workspaces.filter(
                            (w) =>
                                w.workspaceUserId ===
                                loggedInUser.lastUsedWorkspaceUserId,
                        );
                        await this.setCurrentWorkspace(
                            currentWorkspace || {},
                            false,
                            workspaces,
                        );
                        this.emit("loggedin", loggedInUser);
                    })
                    .catch(() => (this.workspace = {}));
            } else {
                this.workspace = {};
                this.emit("loggedin", loggedInUser);
            }
            return loggedInUser;
        });
    }

    getCurrentUser() {
        return this.makeRequest("/me");
    }

    samlLogin() {
        return this.makeRequest("/saml/init?RelayState=portal");
    }

    getJobs(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/jobs`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getWorkspaceUsers(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/workspace_users`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getInstrument(id) {
        return this.makeRequest(`/instruments/${id}`);
    }

    getWorkspaceUserCsv(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/workspace_users/export.csv`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path, RequestType.POST, {}, "text/csv");
    }

    getInviteTokensCsv(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/invite_tokens/export.csv`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path, RequestType.POST, {}, "text/csv");
    }

    getWorkspaceUserXls(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/workspace_users/export.xlsx`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(
            path,
            RequestType.POST,
            {},
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
    }

    getSmartesterCsv(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/instruments/export.csv`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path, RequestType.POST, {}, "text/csv");
    }

    getSmartesterXls(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/instruments/export.xlsx`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(
            path,
            RequestType.POST,
            {},
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
    }

    getInviteTokensXls(queryParameters) {
        const parameters = this.getParameters(queryParameters);

        let path = `/invite_tokens/export.xlsx`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(
            path,
            RequestType.POST,
            {},
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
    }

    getAgreements() {
        return this.makeRequest("/pages/export", RequestType.POST, {
            filter: {
                permalink: {
                    eq: [
                        "privacy_policy",
                        "terms_and_conditions",
                        "end_user_license_agreement",
                    ],
                },
            },
        });
    }

    getNotesExport(data) {
        const URL = `/notes/export.json?workspace_id=${this.workspace.id}&page[size]=-1`;

        return this.makeRequest(URL, RequestType.POST, data);
    }

    forgotPassword(username) {
        return this.makeRequest("/passwords", RequestType.POST, {
            username: username,
        });
    }

    verifyForgotPasswordCode(resetCode) {
        return this.makeRequest("/passwords/verify", RequestType.POST, {
            token: resetCode,
        });
    }

    resetPassword(resetCode, password, passwordConfirmation) {
        return this.makeRequest("/passwords/reset", RequestType.POST, {
            token: resetCode,
            password: password,
            password_confirmation: passwordConfirmation,
        });
    }

    getActivities(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/activities`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getIncidentsExport(data) {
        const URL = `/incidents/export.json?workspace_id=${this.workspace.id}&page[size]=-1`;

        return this.makeRequest(URL, RequestType.POST, data);
    }

    getUploads(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/uploads`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getActivity(id) {
        return this.makeRequest(`/activities/${id}`);
    }

    getJob(id) {
        return this.makeRequest(`/jobs/${id}`);
    }

    getPressureTest(id) {
        return this.makeRequest(`/pressure_tests/${id}`);
    }

    getMetaDataItem(id) {
        return this.makeRequest(`/meta_data_items/${id}`);
    }

    getPressureTestMetaDataItem(id) {
        return this.makeRequest(`/pressure_test_meta_data_items/${id}`);
    }

    getLocation(id) {
        return this.makeRequest(`/locations/${id}`);
    }

    getLocationURL(id) {
        return this.makeRequest(`/meta_data_items/${id}/map`);
    }

    getPressureTestParameter(id) {
        return this.makeRequest(`/pressure_test_parameters/${id}`);
    }

    getReadings(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/readings/charts`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getReading(id) {
        return this.makeRequest(`/readings/${id}`);
    }

    getWorkspaces() {
        return this.makeRequest(`/me/workspaces`);
    }

    async updateProfile(data, emitUser) {
        const updatedUser = await this.makeRequest(
            `/me`,
            RequestType.PUT,
            data,
            "application/json",
            null,
        );

        if (emitUser) {
            this.emit("loggedin", updatedUser);
        }

        return updatedUser;
    }

    signUp(data) {
        return this.makeRequest(`/signup`, RequestType.POST, { user: data });
    }

    resend() {
        return this.makeRequest(`/signup/resend`, RequestType.POST, {});
    }

    verifySignUp(code) {
        return this.makeRequest(
            `/signup/verify?token=${code}`,
            RequestType.POST,
            {},
        );
    }

    getActivityCSV(data, params) {
        var URL = `/activities/export.csv?workspace_id=${this.workspace.id}&page[size]=-1`;

        if (params) {
            if (params["remoteUpdatedAt"]) {
                URL += `&sort[jobs.remote_updated_at]=${params["remoteUpdatedAt"]}`;
            }

            if (params["createdAt"]) {
                URL += `&sort[jobs.created_at]=${params["createdAt"]}`;
            }
        } else {
            URL += `&sort[jobs.created_at]=desc`;
        }

        return this.makeRequest(URL, RequestType.POST, data, "text/csv");
    }

    getActivityXLSX(data, params) {
        var URL = `/activities/export.xlsx?workspace_id=${this.workspace.id}&page[size]=-1`;

        if (params) {
            if (params["remoteUpdatedAt"]) {
                URL += `&sort[jobs.remote_updated_at]=${params["remoteUpdatedAt"]}`;
            }

            if (params["createdAt"]) {
                URL += `&sort[jobs.created_at]=${params["createdAt"]}`;
            }
        } else {
            URL += `&sort[jobs.created_at]=desc`;
        }

        return this.makeRequest(
            URL,
            RequestType.POST,
            data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
    }

    getActivitiesPDF(id) {
        return this.makeRequest(
            `/pdf/activities.pdf?workspace_id=${this.workspace.id}&filter[job_id][eq]=${id}&filter[completed_at][neq]=&sort[remote_updated_at]=asc`,
            RequestType.GET,
            {},
            "application/pdf",
        );
    }

    checkActivitiesDataIntegrity(id) {
        return this.makeRequest(
            `/pdf/activities/data_integrity?workspace_id=${this.workspace.id}&filter[job_id][eq]=${id}&filter[completed_at][neq]=`,
        );
    }

    checkActivityDataIntegrity(id) {
        return this.makeRequest(
            `/pdf/activities/data_integrity?workspace_id=${this.workspace.id}&filter[id][eq]=${id}`,
        );
    }

    getActivityPDF(id) {
        return this.makeRequest(
            `/pdf/activities.pdf?workspace_id=${this.workspace.id}&filter[id][eq]=${id}`,
            RequestType.GET,
            {},
            "application/pdf",
        );
    }

    leaveWorkspace(id) {
        return this.makeRequest(`/me/workspaces/${id}`, RequestType.DELETE);
    }

    verifyPassword(data) {
        return this.makeRequest("/me/verify_password", RequestType.POST, data);
    }

    deleteProfile(data) {
        return this.makeRequest("/me", RequestType.DELETE, data);
    }

    getOrganisations(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/organisations`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getOrganisation(id) {
        return this.makeRequest(`/organisations/${id}`);
    }

    createInviteToken(data) {
        return this.makeRequest("/invite_tokens", RequestType.POST, data);
    }

    resendInviteToken(id) {
        return this.makeRequest(
            `/invite_tokens/${id}/resend`,
            RequestType.POST,
            {},
        );
    }

    getInviteTokens(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/invite_tokens`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getIncidents(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/incidents`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    acceptInvite(data) {
        return this.makeRequest("/me/accept_invite", RequestType.POST, data);
    }

    getWorkspaceUser(id) {
        return this.makeRequest(`/workspace_users/${id}`);
    }

    getInviteToken(id) {
        return this.makeRequest(`/invite_tokens/${id}`);
    }

    deleteInviteToken(id) {
        return this.makeRequest(`/invite_tokens/${id}`, RequestType.DELETE);
    }

    editWorkspaceUser(id, data) {
        return this.makeRequest(
            `/workspace_users/${id}`,
            RequestType.PATCH,
            data,
        );
    }

    deleteWorkspaceUser(id) {
        return this.makeRequest(`/workspace_users/${id}`, RequestType.DELETE);
    }

    getInstruments(queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/instruments`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }

    getInstrumentCertificate(id, historicId) {
        return this.makeRequest(
            `/instruments/${id}/certificates${
                historicId ? "/" + historicId : ""
            }`,
            RequestType.GET,
            {},
            "application/pdf",
        );
    }

    getInstrumentLifecycle(id, queryParameters) {
        const parameters = this.getParameters(queryParameters);
        let path = `/instruments/${id}/lifecycle`;
        if (parameters) {
            path += `?${parameters}`;
        }

        return this.makeRequest(path);
    }
}

export default new SmartesterAPI();
