import React, { lazy } from "react";

import WarningIcon from "@mui/icons-material/WarningRounded";

import noLocationIcon from "resources/noLocation.svg";

const Card = lazy(() => import("components/Card"));

const LocationCard = ({ coordinates, locationUrl, warning }) => {
    var lat = "";
    var lon = "";
    var point = null;
    if (coordinates && locationUrl) {
        [point, lat, lon] = coordinates //eslint-disable-line no-unused-vars
            .replace(/[()]/g, "")
            .split(" ");
    }
    return (
        <div className="location">
            <Card>
                {coordinates && locationUrl ? (
                    <div className="location-card">
                        <h4
                            style={{
                                margin: "12px 0 20px 12px",
                            }}
                        >
                            Location
                        </h4>
                        <div
                            style={{
                                marginRight: "12px",
                                marginLeft: "12px",
                                marginBottom: "20px",
                            }}
                        >
                            <div
                                className="location-img"
                                style={{
                                    backgroundImage: `url(${locationUrl})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}
                            ></div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "12px" }}>
                            <p className="color-neutral-light subtitle-1">
                                GPS:&nbsp;
                            </p>
                            <p className="color-neutral-light body-1">
                                {lon}, {lat}
                            </p>
                            {warning && (
                                <WarningIcon
                                    stroke={"#FF9C50"}
                                    stoke-width={1}
                                    style={{ marginLeft: 6, color: "#FFDA5C" }}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="location-card">
                        <h4
                            style={{
                                margin: "12px 0 156px 12px",
                            }}
                        >
                            Location
                        </h4>
                        <div style={{ textAlign: "center" }}>
                            <img
                                src={noLocationIcon}
                                className="no-location-icon"
                                alt="no-location"
                            />
                            <p className="color-neutral-dark body-1">
                                This Test Doesn't Have a Location
                            </p>
                        </div>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default LocationCard;
