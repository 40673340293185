import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import { BASE_PATH } from "config";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getcontentanchorel={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
));

const ProfilePill = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            className={
                props.minimised
                    ? "profile-mobile-container"
                    : "profile-container"
            }
        >
            <div
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="profile-button"
            >
                {props.user.fileUrl ? (
                    <div
                        className={
                            props.minimised ? "user-mobile-image" : "user-image"
                        }
                    >
                        <img
                            src={`${BASE_PATH.replace(/\/$/, "")}${
                                props.user.fileUrl
                            }`}
                            alt="user-img"
                        />
                    </div>
                ) : (
                    <AccountCircleIcon
                        className={
                            props.minimised ? "user-mobile-icon" : "user-icon"
                        }
                    />
                )}
                {!props.minimised && (
                    <p className="user-name">
                        {props.user.firstName && props.user.lastName
                            ? `${props.user.firstName} ${props.user.lastName}`
                            : props.user.username}
                    </p>
                )}
            </div>
            <StyledMenu
                id="customised-profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    className="profile-menu-li"
                    style={{ padding: "15px 16px" }}
                    onClick={() => {
                        handleClose();
                        props.history.replace("/profile");
                    }}
                >
                    <ListItemIcon className="profile-menu-icon">
                        <PersonIcon />
                    </ListItemIcon>
                    <p className="profile-link">My Profile</p>
                </MenuItem>
                <MenuItem
                    style={{ padding: "14px 16px" }}
                    onClick={() =>
                        window.open("https://support.smartester.com")
                    }
                >
                    <p className="profile-link">Help & Support</p>
                </MenuItem>
                <MenuItem
                    style={{ padding: "14px 16px" }}
                    onClick={props.logout}
                >
                    <p className="profile-link">Logout</p>
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

export { ProfilePill };
