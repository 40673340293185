import React, { useContext } from "react";

const SmartesterAPIContext = React.createContext(null);
export const SmartesterAPIProvider = SmartesterAPIContext.Provider;

export const useApi = () => {
    return useContext(SmartesterAPIContext);
};

export default SmartesterAPIContext;
