import React, { lazy } from "react";

import moment from "moment";

import { useWindowDimensions, formatTime, roundPressure } from "utilities";

const Card = lazy(() => import("components/Card"));

const DetailRow = ({ mobile, header, data, finalRow }) => {
    return mobile ? (
        <div className={`details-mobile-row ${finalRow ? "final" : ""}`}>
            <p className="overline color-dark">{header}</p>
            <p className="body-1 color-black">{data}</p>
        </div>
    ) : (
        <div className={`details-row ${finalRow ? "final" : ""}`}>
            <p className="body-2 color-dark">{header}</p>
            <p className="body-2 bold color-black align-right">{data}</p>
        </div>
    );
};

const DetailsCard = ({
    activity,
    pressureTest,
    metaDataItem,
    pressureTestParameter,
    readingTestStart,
    readingTestEnd,
    pressureTestMetaDataItem,
    workspace,
}) => {
    const { width } = useWindowDimensions();
    const mobile = width <= 600;
    return (
        <div className="details">
            <Card style={{ padding: "8px 0 8px 8px" }}>
                <div className="details-card">
                    <h4
                        style={{
                            margin: "12px 0 22px 12px",
                        }}
                    >
                        Test Data
                    </h4>
                    <DetailRow
                        mobile={mobile}
                        header={workspace?.locationReferenceLabel ?? "MPRN"}
                        data={activity.locationReference}
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"Installer"}
                        data={activity.createdByKnownAs}
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"Creation Date"}
                        data={moment(activity.remoteCreatedAt).format(
                            "DD/MM/YYYY - HH:mm:ss",
                        )}
                    />
                    {pressureTestParameter.stabilisationMinimumDuration !==
                    null ? (
                        <DetailRow
                            mobile={mobile}
                            header={"Stabilisation Period"}
                            data={formatTime(
                                pressureTestParameter.stabilisationMinimumDuration *
                                    1000,
                            )}
                        />
                    ) : (
                        <></>
                    )}
                    <DetailRow
                        mobile={mobile}
                        header={"Test Period"}
                        data={
                            activity.activityTypeTestParameterTestMinimumDuration
                                ? formatTime(
                                      activity.activityTypeTestParameterTestMinimumDuration *
                                          1000,
                                  )
                                : "No Data"
                        }
                    />
                    {pressureTestParameter.testAllowablePressureDecrease !==
                    null ? (
                        <DetailRow
                            mobile={mobile}
                            header={"Allowable Pressure Decrease"}
                            data={roundPressure(
                                pressureTestParameter.testAllowablePressureDecrease,
                            )}
                        />
                    ) : (
                        <></>
                    )}
                    {pressureTestParameter.testAllowablePressureIncrease !==
                    null ? (
                        <DetailRow
                            mobile={mobile}
                            header={"Allowable Pressure Increase"}
                            data={roundPressure(
                                pressureTestParameter.testAllowablePressureIncrease,
                            )}
                        />
                    ) : (
                        <></>
                    )}
                    <DetailRow
                        mobile={mobile}
                        header={"Time at Start of Test Period"}
                        data={
                            pressureTest.testStartedAt
                                ? moment(pressureTest.testStartedAt).format(
                                      "DD/MM/YYYY - HH:mm:ss",
                                  )
                                : "No Data"
                        }
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"Pressure at Start of Test Period"}
                        data={
                            (readingTestStart?.measurement ??
                                pressureTest.testStartMeasurement) !== null
                                ? `${roundPressure(
                                      readingTestStart?.measurement ??
                                          pressureTest.testStartMeasurement,
                                  )} mbar`
                                : "No Data"
                        }
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"Time at End of Test Period"}
                        data={
                            pressureTest.testEndedAt
                                ? moment(pressureTest.testEndedAt).format(
                                      "DD/MM/YYYY - HH:mm:ss",
                                  )
                                : "No Data"
                        }
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"Pressure at End of Test Period"}
                        data={
                            (readingTestEnd?.measurement ??
                                pressureTest.testEndMeasurement) !== undefined
                                ? `${roundPressure(
                                      readingTestEnd?.measurement ??
                                          pressureTest.testEndMeasurement,
                                  )} mbar`
                                : "No Data"
                        }
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"SMARTester"}
                        data={
                            activity.pressureTestInstrumentSerialNumber
                                ? activity.pressureTestInstrumentSerialNumber
                                : "No Data"
                        }
                    />
                    {pressureTestMetaDataItem?.instrumentExpiresOn && (
                        <DetailRow
                            mobile={mobile}
                            header={"SMARTester Calibration Expiry"}
                            data={moment(
                                pressureTestMetaDataItem?.instrumentExpiresOn,
                                "YYYY-MM-DD",
                            ).format("DD/MM/YYYY")}
                        />
                    )}
                    <DetailRow
                        mobile={mobile}
                        header={"App Version"}
                        data={
                            metaDataItem.softwareVersion
                                ? metaDataItem.softwareVersion
                                : "No Data"
                        }
                    />
                    <DetailRow
                        mobile={mobile}
                        header={"OS Version"}
                        data={
                            metaDataItem.osVersion
                                ? metaDataItem.osVersion
                                : "No Data"
                        }
                        finalRow
                    />
                </div>
            </Card>
        </div>
    );
};

export default DetailsCard;
